.edit-profile-field-total {
  width: 100%;
}

.search-icon {
  background-color: #37a11a;
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.socialLink:hover {
  color: #37a11a !important;
}
.text-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.pagination .page-item .page-link {
  background-color: #0f131f !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #37a11a !important;
  border-color: #37a11a !important;
}
@media screen and (max-width: 500px) {
  .pagination {
    display: none;
  }
}
.pagination .page-item .page-link {
  z-index: 3;
  color: #fff;
  background-color: #37a11a !important;
  border-color: #37a11a !important;
}

.button-load-more button {
  background-color: #37a11a;
}

.search-field-total {
  width: 70%;
}

.user-name {
  font-family: Tajawal, SemiBold !important;
  font-size: 16pt;
  margin: 0;
  color: #37a11a;
}

.user-location {
  font-family: Tajawal, SemiBold !important;
  font-size: 10pt;
  margin: 0;
}

.user-description {
  font-family: Tajawal, SemiBold !important;
  font-size: 10pt;
  margin: 0;
  word-break: break-all;
}

.profile-prop {
  font-size: 10pt;
  padding-left: 5px;
  font-family: Tajawal, SemiBold !important;
}

.user-details {
  font-size: 10pt;
  color: #37a11a;
  margin: 0;
  font-family: Tajawal, SemiBold !important;
}

.contact-button {
  background-color: white;
  text-align: center;
  height: 40px;
  width: 100%;
  bottom: 0;
  padding-top: 8px;
}

.contact-button button {
  background-color: #37a11a;
  color: white;
  font-size: 10pt;
  width: 100%;
  height: 35px;
  text-align: center;
  font-family: Tajawal, SemiBold !important;
}

.search-box {
  position: fixed;
  width: 100%;
  background-color: white;
  padding: 15px 10px;
  height: 50px;
  z-index: 10;
}
