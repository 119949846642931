@media (min-width: 1000px) {
  .userCard {
    width: 55%;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .userCard {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .userCard {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 375px) {
  .userCard {
    width: 90%;
    margin: auto;
  }
}
.material-icons {
  font-size: 36px;
  color: #37a11a;
}

.title-edit-personal {
  color: black;
  font-size: 16pt;
  text-align: center;
  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
  padding-right: 30px;
}

.title-edit-personal-description {
  color: black;
  font-size: 12pt;
  text-align: center;
  font-weight: 400;
  font-family: Tajawal, SemiBold !important;
}

.edit-profile-icon {
  color: #37a11a;
  font-size: 24px;
  padding-right: 20px;
}

.edit-profile-password {
  padding-left: 20px;
}

.edit-profile-label {
  font-family: Tajawal, SemiBold !important;
}

.edit-profile-field-total {
  width: 100%;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline-thick {
  color: #37a11a !important;
}

.update-button {
  background-color: #37a11a;
  color: white;
  font-size: 13pt;
  width: 100%;
  height: 50px;
  text-align: center;
  font-family: Tajawal, SemiBold !important;
}

::ng-deep .mat-form-field-prefix {
  height: 50px;
  width: 50px;
  background-color: #37a11a;
  margin: auto;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.2em !important;
}

::ng-deep .mat-form-field-prefix img {
  width: 25px;
  height: 25px;
}

input {
  font-family: Tajawal, SemiBold !important;
}
