.aboutus-img {
  width: 375px;
  display: flex;
  position: relative;
  margin: 0 auto;
}

.aboutus-title {
  color: black;
  font-size: 20pt;
  text-align: left;
  margin-top: -40px;
  width: 100%;
  font-weight: 400;
  font-family: Tajawal, SemiBold !important;
}

.aboutus-description {
  color: black;
  font-size: 12pt;
  text-align: left;
  width: 100%;
  font-weight: 400;
  font-family: Tajawal, SemiBold !important;
}

.aboutus-twitter {
  color: black;
  font-size: 12pt;
  text-align: center;
  padding-top: 30px;
  width: 100%;
  font-weight: 400;
  font-family: Tajawal, SemiBold !important;
}

.twitter-link {
  padding-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-content {
  padding: 0 16px;
  text-align: center;
}
/* .aboutus {
  padding: 0 7rem;
} */
@media (min-width: 1025px) {
  .aboutus {
    padding: 0 20rem;
  }
}
@media (max-width: 1024px) {
  .aboutus {
    padding: 0 10rem;
  }
}
@media (max-width: 992px) {
  .aboutus {
    padding: 0 4rem;
  }
}

@media (max-width: 768px) {
  .aboutus {
    padding: 0 3rem;
  }
}

@media (max-width: 375px) {
  .aboutus {
    padding: 0 2rem;
  }
}
