main.home-page .row-gap-4 {
  row-gap: 1.5rem !important;
}

main.home-page .card-sign-as-wraper[lang=ar] .card-sign-as {
  text-align: right !important;
}

main.home-page .gap-4 {
  gap: 1.5rem !important;
}

main.home-page .logo-partners{
  width:110px
}


main.home-page .text-heilight {
	color:  #37A11A
}

main.home-page .btn {
	border-radius: 10px;
	background-color: #37A11A !important;
	padding: 8px 35px;
	font-weight: 500;
	border: none;
	padding: 12px 25px;
  font-size: 14px !important;
}

main.home-page .btn:hover {
	background-color: #318f16 !important;
  color: white !important;
}

main.home-page .btn-outline-primary {
	border-radius: 10px;
	background-color: white !important;
  border: 1px solid #37A11A !important;
  color: #37A11A !important;
  text-transform: uppercase;
	font-weight: 500;
	border: none;
  padding: 12px 25px;
  font-size: 14px !important;
}

@media only screen and (max-width: 600px) {
  main.home-page .btn {
    width: 100%;
    padding: 12px 10px;
  }
}

@media only screen and (max-width: 600px) {
	main.home-page header {
	  height: 70vh;
	}
	main.home-page header .text-wraper {
		width: 75% !important;
	}
}

main.home-page .header-background {
  object-fit: cover;
  background-position: center;
}

.fs-6{
  font-size:1rem
}
.fs-5{
  font-size:1.25rem
}
.fs-3{
  font-size:1.75rem
}
.fs-2{
  font-size:2rem
}
@media (min-width:576px){
  .fs-6{
      font-size:1.2rem
  }
  .fs-5{
      font-size:1.4rem
  }
  .fs-3{
      font-size:1.8rem
  }
  .fs-2{
      font-size:2rem
  }
}