@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

footer {
	border-top: 1px solid #EAEAEA;
  font-family: "Tajawal", sans-serif;
  font-style: normal;
}

.text-heilight {
	color:  #37A11A
}
.MuiSvgIcon-colorWhite {
	color:  #FFFFFF !important
}


.fw-medium {
  font-weight: 500;
}

.fw-semobold {
  font-weight: 500;
}

.fw-bold {
  font-weight: 500;
}


footer .logo-brand {
	width: 145px;
}

footer[dir='rtl'] .justify-content-sm-start {
	text-align: right;
}

footer .footer-list {
	padding-inline-start: 0px;
  display: flex;
  gap: 18px;
}

@media (max-width:575px){
  footer .footer-list li {
    text-align: center;
  }
}

footer .footer-list a {
		text-decoration: none;
		color: #333;
		transition: all 0.3s ease-in-out;
}

footer .footer-list a:hover {
	color: #37A11A
}

footer .colord-text {
	color: #37A11A;
}

footer .social-icon {
	color: #333333;
	transition: all 0.3s ease-in-out;
}

@media (max-width:575px){
  footer .social-icon {
    display: block;
    text-align: center;
  }
}


footer .social-icon:hover {
	color: #37A11A;
}