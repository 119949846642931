.title-faq {
  color: black;
  font-size: 24pt;
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-family: Tajawal, SemiBold !important;
}

.question {
  padding-top: 15px;
  padding-bottom: 15px;
}

.faq {
  padding: 10px;
}

.question-title {
  font-family: Tajawal, SemiBold !important;
  font-weight: 600;
}

.question-description {
  font-family: Tajawal, SemiBold !important;
}

@media (min-width: 1000px) {
  .faq {
    width: 55%;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .faq {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .faq {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 375px) {
  .faq {
    width: 90%;
    margin: auto;
  }
}
