.material-icons {
  font-size: 36px;
  color: #37a11a;
}

.card-avatar {
  background-size: cover;
  margin-right: 16px;
}

.socialLink:hover {
}

.green-spinner {
  width: 100vw;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.mat-card-header .mat-card-title {
  margin-bottom: 17px;
  font-family: Tajawal, SemiBold !important;
}

.mat-card-subtitle {
  margin-bottom: 11px;
  font-family: Tajawal, SemiBold !important;
}

.mat-card-header-text {
  margin: 3px 16px 0 16px;
  font-family: Tajawal, SemiBold !important;
}

.pin-icon {
  font-size: 19px;
  color: #595959;
  vertical-align: sub;
}

.mat-card-avatar {
  height: 91px;
  width: 91px;
}

.second-title {
  font-size: 14px !important;
  font-weight: 400;
  color: #262626;
  font-family: Tajawal, SemiBold !important;
}

.sub-container {
  margin: 15px 0;
}

.green-heading {
  color: #37a11a;
  font-size: 12pt;
  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
}

.button-edit {
  margin-top: auto;
  margin-bottom: auto;
}

.profile-description {
  display: inline;
  padding-right: 10px;
  font-size: 14px;
  line-height: 20pt;
  word-break: break-word;
  font-family: Tajawal, SemiBold !important;
}

.social-links {
  display: inline;
  padding-right: 10px;
  font-family: Tajawal, SemiBold !important;
}

/* Profile Props */

.profile-prop {
  font-size: 16px;
  align-self: center;
  padding-left: 5px;
  font-family: Tajawal, SemiBold !important;
}

.profile-prop-card-header {
  background-color: #fafafa;
  padding: 20px 16px;
  font-family: Tajawal, SemiBold !important;
  border-bottom: 1px solid #d9d9d9;
  font-weight: 500;
}

.profile-prop-card-header-content {
  width: 100%;
  display: flex;
  font-family: Tajawal, SemiBold !important;
  justify-content: space-between;
}

.profile-prop-chip-list {
  width: 100%;
  font-family: Tajawal, SemiBold !important;
}
.MuiChip-root.MuiChip-colorPrimary {
  background-color: #37a11a !important;
  border-radius: 5px !important;
}

.profile-prop-card-content {
  padding: 22px;
  font-family: Tajawal, SemiBold !important;
}

::ng-deep .mat-card-header-text {
  margin: 0 !important;
  font-family: Tajawal, SemiBold !important;
}

.mat-card-header-text {
  font-size: 16pt;
  font-family: Tajawal, SemiBold !important;
}

.custom-card-header {
  font-family: Tajawal, SemiBold !important;
  display: flex;
}
.page-card-header {
  padding: 27px 0;
  font-size: 18pt;
  font-weight: 600;
}
.title-show-profile {
  color: black;
  font-size: 16pt;
  text-align: center;
  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
  padding-right: 30px;
}
.contact-button {
  background-color: white;
  border-top: 1px solid #bbb;
  text-align: center;
  position: fixed;
  height: 90px;
  width: 100%;
  bottom: 0;
  padding-top: 20px;
}

.contact-button button {
  background-color: #37a11a;
  color: white;
  font-size: 13pt;
  width: 80%;
  height: 40px;
  text-align: center;
  font-family: Tajawal, SemiBold !important;
}

.infos {
  padding-bottom: 80px !important;
}

@media (min-width: 1000px) {
  .infos {
    width: 55%;
    margin: auto;
  }
  .contact-button div {
    width: 65%;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .infos {
    width: 70%;
    margin: auto;
  }
  .contact-button div {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .infos {
    width: 90%;
    margin: auto;
  }
  .contact-button div {
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 375px) {
  .infos {
    width: 90%;
    margin: auto;
  }
  .contact-button div {
    width: 90%;
    margin: auto;
  }
}
