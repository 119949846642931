@media (min-width: 1000px) {
  .userCard {
    width: 55%;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .userCard {
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .userCard {
    width: 90%;
    margin: auto;
  }
}

@media (max-width: 375px) {
  .userCard {
    width: 90%;
    margin: auto;
  }
}
.contact-field-total {
  font-size: 16px;
  width: 100%;
  color: #37a11a !important;
  outline: #37a11a;
}

.profile-contact-email,
.profile-contact-message,
.profile-contact-name {
  display: inline;
  padding-right: 10px;
  font-size: 14px;
  line-height: 20pt;
  font-family: Tajawal, SemiBold !important;
}

.contact-label {
  font-family: Tajawal, SemiBold !important;
}
.page-card-header {
  padding: 27px 0;
  font-size: 18pt;
  font-weight: 600;
}
.title-contact-name {
  color: #37a11a;
  font-size: 12pt;
  text-align: center;
  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
  padding-left: 5px;
}

.contact-button {
  background-color: white;
  border-top: 1px solid #bbb;
  text-align: center;
  height: 60px;
  width: 100%;
  bottom: 0;
  padding-top: 20px;
}

.contact-button button {
  background-color: #37a11a;
  color: white;
  font-size: 13pt;
  width: 80%;
  height: 40px;
  text-align: center;
  font-family: Tajawal, SemiBold !important;
}

.contact-button button.disabled {
  background-color: gray;
  color: black;
}

.MuiTextField-root.Mui-focused {
  color: #37a11a !important;
  outline: #37a11a 2px;
}
label.Mui-focused {
  color: #37a11a !important;
  outline: #37a11a;
}
.green-spinner {
  margin-left: auto;
  margin-right: auto;
}

.MuiButton-root.MuiButton-contained:hover {
  background-color: #37a11a;
}
