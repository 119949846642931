nav {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0,0,0,0.07);
}

.logo-brand {
	width: 145px
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
	color: #37A11A
}

nav .navbar-toggler {
	border: none;
	box-shadow: none !important;
	background-color: transparent;
}

.switch-lang label {
	cursor: pointer;
  margin-bottom: 0 !important;
}

.switch-lang input[type=checkbox] {
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.switch-lang label img {
	width: 30px;
}

@media only screen and (max-width: 991.99px ){
	.menu-resonsive {
		display: none !important;
	}
}

nav .row-gap-4 {
  row-gap: 1.5rem !important;
}

nav .gap-4 {
  gap: 1.5rem !important;
}

nav .btn {
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
  padding: 9px 12px;
}

nav .btn:hover {
  background-color: #319216 !important;
}

nav .nav-menu {
  list-style: none;
  gap: 2.5rem;
  padding: 0;
}

@media (max-width:991.99px){
  nav .nav-menu {
    flex-direction: column;
    gap: 1.25rem;
  }
}

@media (max-width:991.99px){
  nav .nav-menu li {
    width: 100%;
    text-align: center;
  }
}

@media (max-width:991.99px){
  nav .offcanvas-menu {
    position: fixed;
    width: 400px;
    background-color: white;
    top: 0;
    right: 0;
    height: 100%;
    padding: 20px;
    box-shadow: -12px 0px 22px rgba(0,0,0,0.09);
    transition: all 0.5s ease-in-out;
    right: -110%;
    z-index: 1000;
  }
}

@media (max-width:991.99px){
  nav .offcanvas-menu.open {
    right: 0;
  }
}

@media (max-width:991.99px){
  nav .overlay-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: -4;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
}

@media (max-width:991.99px){
  nav .overlay-menu.open {
    opacity: 1;
    z-index: 1;
    visibility: visible;
  }
}

nav .nav-menu li {
  font-weight: 500;
  text-transform: uppercase;
}

nav .nav-menu li a {
  color: #222;
}

nav .nav-menu li a:hover {
  color: #37A11A;
}

nav .toggel-menu {
  padding: 2;
  border: none;
  margin-inline-start: 12px;
  background-color: transparent;
}

nav .d-flex.gap-2 {
  gap: 0.7rem !important;
}

nav .toggel-menu svg {
  stroke: #222;
  width: 32px;
  height: 32px;
}

nav .dropdown .user-Profile {
  padding: 0;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

nav .dropdown .user-Profile {
  display: flex;
  align-items: center;
  gap: 4px;
}

nav .dropdown .user-Profile:after {
  color: #333;
  margin: 0;
}

nav .dropdown .user-Profile .image-use {
  display: inline-block;
}

nav .dropdown .user-Profile .image-use img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
}

nav .dropdown .user-Profile .letter-use {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  font-size: 23px;
  border-radius: 100%;
  background-color: #319216;
}

nav .dropdown .user-Profile .letter-use span {
  color: #fff;
  text-transform: uppercase;
  margin: 0 3px;
  
}

nav .dropdown .user-Profile .user-name {
    color: #333;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    max-width: 65px; 
}


nav .dropdown .user-Profile .user-name {
  color: #333;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width:991.99px){
  nav .dropdown .user-Profile .user-name {
    max-width: 40px; 
  }
}