@media (min-width: 1000px) {
  .userCard {
    width: 55%;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .userCard {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .userCard {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 375px) {
  .userCard {
    width: 90%;
    margin: auto;
  }
}
.material-icons {
  font-size: 36px;
  color: #37a11a;
}

.title-edit-personal {
  color: black;
  font-size: 16pt;
  text-align: center;
  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
  padding-right: 30px;
}

.more-about {
  font-family: Tajawal, SemiBold !important;
}

.more-about-field {
  font-family: Tajawal, SemiBold !important;
}

.edit-profile-icon {
  color: #37a11a;
  font-size: 24px;
  padding-right: 20px;
}

.edit-profile-password {
  padding-left: 20px;
}

.edit-profile-label {
  font-family: Tajawal, SemiBold !important;
}

.edit-profile-field-total {
  width: 100%;
}

.update-button {
  background-color: #37a11a;
  color: white;
  font-size: 13pt;
  width: 100%;
  height: 50px;
  text-align: center;
  font-family: Tajawal, SemiBold !important;
}
