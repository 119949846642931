@media (min-width: 1000px) {
  .userCard {
    width: 55%;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .userCard {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .userCard {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 375px) {
  .userCard {
    width: 90%;
    margin: auto;
  }
}
.material-icons {
  font-size: 36px;
  color: #37a11a;
}

.title-edit-personal {
  color: black;
  font-size: 16pt;
  text-align: center;
  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
  padding-right: 30px;
}

.change-password-sub {
  font-size: 12pt;
  font-weight: 500;
  padding-left: 15px;
  font-family: Tajawal, SemiBold !important;
}

.edit-profile-icon {
  color: #37a11a;
  font-size: 24px;
  padding-right: 20px;
}

.edit-profile-password {
  padding-left: 20px;
}

.edit-profile-label {
  font-family: Tajawal, SemiBold !important;
}

.edit-profile-field-total {
  width: 100%;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline-thick {
  color: #37a11a !important;
}

.or-box {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.or-signup {
  text-align: center;
  font-family: Tajawal, SemiBold !important;
  font-weight: 400;
}

.update-button {
  background-color: #37a11a;
  color: white;
  font-size: 13pt;
  width: 100%;
  height: 50px;
  text-align: center;
  font-family: Tajawal, SemiBold !important;
}

.deactivate-button {
  background-color: red;
  color: white;
  font-size: 13pt;
  width: 100%;
  height: 50px;
  text-align: center;
  font-family: Tajawal, SemiBold !important;
}

::ng-deep .mat-select {
  font-family: Tajawal, SemiBold !important;
}

::ng-deep .mat-checkbox-layout {
  white-space: normal !important;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #37a11a;
}
::ng-deep
  .mat-slide-toggle.mat-checked:not(.mat-disabled)
  .mat-slide-toggle-thumb {
  background-color: #37a11a; /*replace with your color*/
}

::ng-deep
  .mat-slide-toggle.mat-checked:not(.mat-disabled)
  .mat-slide-toggle-bar {
  background-color: rgba(55, 161, 26, 0.7); /*replace with your color*/
}
::ng-deep
  .mat-slide-toggle.mat-unchecked:not(.mat-disabled)
  .mat-slide-toggle-thumb {
  background-color: #37a11a; /*replace with your color*/
}

::ng-deep
  .mat-slide-toggle.mat-unchecked:not(.mat-disabled)
  .mat-slide-toggle-bar {
  background-color: rgba(55, 161, 26, 0.7); /*replace with your color*/
}

.passwordClass {
  color: black;
  font-size: 16pt;
  text-align: center;
  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
  padding-right: 30px;
  color: #37a11a !important;
}
.activationClass {
  color: black;
  font-size: 16pt;

  font-weight: 500;
  font-family: Tajawal, SemiBold !important;
}
.passwordClass:hover {
  color: #37a11a !important;
}
