@media (min-width: 1000px) {
  .userCard {
    width: 55%;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .userCard {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .userCard {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 375px) {
  .userCard {
    width: 90%;
    margin: auto;
  }
}
.pin-icon {
  font-size: 19px;
  color: #595959;
  vertical-align: sub;
}

.second-title {
  font-size: 14px !important;
  font-weight: 400;
  color: #262626;
  font-family: Tajawal, SemiBold !important;
}

/* Profile Props */

.profile-prop {
  font-size: 12pt;
  padding-left: 5px;
  font-family: Tajawal, SemiBold !important;
}

.profile-prop-card-header {
  background-color: #fafafa;
  padding: 20px 16px;
  font-family: Tajawal, SemiBold !important;
  border-bottom: 1px solid #d9d9d9;
  font-weight: 500;
}

.profile-prop-card-header-content {
  width: 100%;
  display: flex;
  font-family: Tajawal, SemiBold !important;
  justify-content: space-between;
}

.profile-prop-chip-list {
  width: 100%;
  font-family: Tajawal, SemiBold !important;
}

.profile-prop-card-content {
  padding: 22px;
  font-family: Tajawal, SemiBold !important;
}

.custom-card-header {
  font-family: Tajawal, SemiBold !important;
  display: flex;
}
.MuiChip-root.MuiChip-colorPrimary {
  background-color: #37a11a !important;
  border-radius: 5px !important;
}
.MuiChip-root.MuiChip-colorPrimary:first-child{
  margin: 0 8px;
}