.title-partners {
  color: black;
  font-size: 24pt;
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-family: Tajawal, SemiBold !important;
}

.partner-list {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
}
