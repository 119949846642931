ul.lst-kix_yp2zjf1tqt42-1 {
  list-style-type: none;
}
ul.lst-kix_yp2zjf1tqt42-0 {
  list-style-type: none;
}
.lst-kix_e2iq44nex7j7-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_e2iq44nex7j7-1 > li:before {
  content: "\0025cb  ";
}
.lst-kix_yp2zjf1tqt42-7 > li:before {
  content: "\0025cb  ";
}
.lst-kix_e2iq44nex7j7-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_e2iq44nex7j7-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_yp2zjf1tqt42-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_yp2zjf1tqt42-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_e2iq44nex7j7-5 > li:before {
  content: "\0025a0  ";
}
.lst-kix_e2iq44nex7j7-2 > li:before {
  content: "\0025a0  ";
}
.lst-kix_e2iq44nex7j7-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_yp2zjf1tqt42-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_yp2zjf1tqt42-8 > li:before {
  content: "\0025a0  ";
}
.lst-kix_e2iq44nex7j7-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_e2iq44nex7j7-6 {
  list-style-type: none;
}
ul.lst-kix_e2iq44nex7j7-5 {
  list-style-type: none;
}
ul.lst-kix_e2iq44nex7j7-8 {
  list-style-type: none;
}
ul.lst-kix_e2iq44nex7j7-7 {
  list-style-type: none;
}
ul.lst-kix_e2iq44nex7j7-2 {
  list-style-type: none;
}
.lst-kix_yp2zjf1tqt42-1 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_e2iq44nex7j7-1 {
  list-style-type: none;
}
ul.lst-kix_e2iq44nex7j7-4 {
  list-style-type: none;
}
.lst-kix_yp2zjf1tqt42-2 > li:before {
  content: "\0025a0  ";
}
ul.lst-kix_e2iq44nex7j7-3 {
  list-style-type: none;
}
.lst-kix_yp2zjf1tqt42-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_yp2zjf1tqt42-8 {
  list-style-type: none;
}
ul.lst-kix_e2iq44nex7j7-0 {
  list-style-type: none;
}
ul.lst-kix_yp2zjf1tqt42-7 {
  list-style-type: none;
}
ul.lst-kix_yp2zjf1tqt42-6 {
  list-style-type: none;
}
ul.lst-kix_yp2zjf1tqt42-5 {
  list-style-type: none;
}
ul.lst-kix_yp2zjf1tqt42-4 {
  list-style-type: none;
}
ul.lst-kix_yp2zjf1tqt42-3 {
  list-style-type: none;
}
.lst-kix_yp2zjf1tqt42-4 > li:before {
  content: "\0025cb  ";
}
.lst-kix_e2iq44nex7j7-7 > li:before {
  content: "\0025cb  ";
}
ul.lst-kix_yp2zjf1tqt42-2 {
  list-style-type: none;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c0 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c3 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 22.5pt;
  font-family: "Arial";
  font-style: normal;
}
.c12 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c2 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.c6 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c13 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 27pt;
  font-family: "Arial";
  font-style: normal;
}
.c8 {
  color: #5d88b3;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 15pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c11 {
  background-color: #ffffff;
  padding: 50px 50px;
}
.c4 {
  color: #5d88b3;
  font-size: 12pt;

  text-decoration: inherit;
}
.c7 {
  padding: 0;
  margin: 0;
}
.c9 {
  color: #333333;
  font-size: 12pt;
}
.c10 {
  color: #5d88b3;
  font-size: 12pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
